import { Component, OnInit } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { IDevice } from 'app/models/device.model';
import { DeviceService } from 'app/services/device.service';
import { MeasureService } from 'app/services/measure.service';
import { IMeasure, Measure } from 'app/models/measure.model';
@Component({
  selector: 'measure-update-dialog',
  templateUrl: './measure-update-dialog.component.html',
})
export class MeasureUpdateDialogComponent implements OnInit {
  measure: IMeasure = new Measure();
  isSaving = false;

  devicesSharedCollection: IDevice[] = [];

  editForm = this.fb.group({
    id: [],
    name: ['', Validators.required],
    description: ['', Validators.required],
    unit: [],
    lastValue: ['', Validators.required],
    minValue: ['', Validators.required],
    maxValue: ['', Validators.required],
    device: ['', Validators.required],
  });

  constructor(
    protected measureService: MeasureService,
    protected deviceService: DeviceService,
    protected activatedRoute: ActivatedRoute,
    protected fb: FormBuilder,
    public activeModal: NgbActiveModal
  ) { }


  ngOnInit(): void {
    this.isSaving = false;
    this.updateForm(this.measure);

    this.loadRelationshipsOptions();
  }

  cancel(): void {
    this.activeModal.dismiss('cancel');
  }

  previousState(): void {
    window.history.back();
  }

  save(): void {
    this.isSaving = true;
    const measure = this.createFromForm();
    if (measure.id !== undefined) {
      this.subscribeToSaveResponse(this.measureService.update(measure));
    } else {
      this.subscribeToSaveResponse(this.measureService.create(measure));
    }
  }

  protected subscribeToSaveResponse(result: Observable<HttpResponse<IMeasure>>): void {
    result.pipe(finalize(() => this.onSaveFinalize())).subscribe({
      next: () => this.onSaveSuccess(),
      error: () => this.onSaveError(),
    });
  }

  protected onSaveSuccess(): void {
    this.activeModal.dismiss(true);
  }

  protected onSaveError(): void {
    // Api for inheritance.
  }

  protected onSaveFinalize(): void {
    this.isSaving = false;
  }

  protected updateForm(measure: IMeasure): void {
    this.editForm.patchValue({
      id: measure.id,
      name: measure.name,
      description: measure.description,
      unit: measure.unit,
      lastValue: measure.lastValue,
      minValue: measure.minValue,
      maxValue: measure.maxValue,
      device: measure.device,
    });

    this.devicesSharedCollection = this.deviceService.addDeviceToCollectionIfMissing(this.devicesSharedCollection, measure.device);
  }

  protected loadRelationshipsOptions(): void {
    this.deviceService
      .query()
      .pipe(map((res: HttpResponse<IDevice[]>) => res.body ?? []))
      .pipe(map((devices: IDevice[]) => this.deviceService.addDeviceToCollectionIfMissing(devices, this.editForm.get('device')!.value)))
      .subscribe((devices: IDevice[]) => (this.devicesSharedCollection = devices));
  }

  protected createFromForm(): IMeasure {
    return {
      ...new Measure(),
      id: this.editForm.get(['id'])!.value,
      name: this.editForm.get(['name'])!.value,
      description: this.editForm.get(['description'])!.value,
      unit: this.editForm.get(['unit'])!.value,
      lastValue: this.editForm.get(['lastValue'])!.value,
      minValue: this.editForm.get(['minValue'])!.value,
      maxValue: this.editForm.get(['maxValue'])!.value,
      device: this.editForm.get(['device'])!.value,
    };
  }
}
