import { IAlert } from 'app/models/alert.model';
import { IDevice } from 'app/models/device.model';

export interface IMeasure {
  id?: number;
  name?: string | null;
  description?: string | null;
  unit?: string | null;
  lastValue?: number | null;
  minValue?: number | null;
  maxValue?: number | null;
  measureActivated?: boolean | null;
  alertActivated?: boolean | null;
  firstColor?: string | null;
  secondColor?: string | null;
  alerts?: IAlert[] | null;
  device?: IDevice | null;
}

export class Measure implements IMeasure {
  constructor(
    public id?: number,
    public name?: string | null,
    public description?: string | null,
    public unit?: string | null,
    public lastValue?: number | null,
    public minValue?: number | null,
    public maxValue?: number | null,
    public measureActivated?: boolean | null,
    public alertActivated?: boolean | null,
    public firstColor?: string | null,
    public secondColor?: string | null,
    public alerts?: IAlert[] | null,
    public device?: IDevice | null
  ) {}
}

export function getMeasureIdentifier(measure: IMeasure): number | undefined {
  return measure.id;
}
