<form name="editForm" role="form" novalidate (ngSubmit)="save()" [formGroup]="editForm">
  <div class="modal-header">
    <h4 class="modal-title" id="organization-heading" data-cy="OrganizationCreateUpdateHeading">
      Créer ou éditer un utilisateur
    </h4>
  </div>

  <div class="modal-body">
    <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
      <div class="alert-body">
        <p>{{ error }}</p>
      </div>
    </ngb-alert>

    <div class="form-group row">
      <div class="col-lg-6">
        <label for="login">Nom d'utilisateur</label>
        <input type="text" class="form-control" name="login" formControlName="login" />
      </div>
      <div class="col-lg-6">
        <label for="email">Email</label>
        <input type="email" class="form-control" name="email" formControlName="email" />
      </div>
    </div>
    <div class="form-group row">
      <div class="col-lg-6">
        <label for="firstName">Prénom</label>
        <input type="text" class="form-control" name="firstName" formControlName="firstName" />
      </div>
      <div class="col-lg-6">
        <label for="firstName">Nom</label>
        <input type="text" class="form-control" name="lastName" formControlName="lastName" />
      </div>
    </div>
    <div class="form-group row">
      <div class="col-lg-12">
        <label for="authority">Privilèges</label>
        <ng-select name="authority" notFoundText="Aucun élément trouvé" [items]="authorities" bindLabel="authority"
          [multiple]="true" formControlName="authorities">
        </ng-select>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancel()">
      <span>Annuler</span>
    </button>

    <button type="submit" id="save-entity" data-cy="entityCreateSaveButton" [disabled]="editForm.invalid || isSaving"
      class="btn btn-primary">
      <span>Sauvegarder</span>
    </button>
  </div>
</form>