import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { ILocation, Location } from '../models/location.model';
import { LocationService } from './location.service';

@Injectable({ providedIn: 'root' })
export class LocationRoutingResolveService implements Resolve<ILocation> {
  constructor(protected service: LocationService, protected router: Router) { }

  resolve(route: ActivatedRouteSnapshot): Observable<ILocation> | Observable<never> {
    const id = route.params['id'];
    if (id) {
      return this.service.find(id).pipe(
        mergeMap((location: HttpResponse<Location>) => {
          if (location.body) {
            return of(location.body);
          } else {
            this.router.navigate(['404']);
            return EMPTY;
          }
        })
      );
    }
    return of(new Location());
  }
}
