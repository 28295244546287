import { Component, Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LocationDeleteDialogComponent } from 'app/main/settings/location-delete-dialog/location-delete-dialog.component';
import { ILocation } from 'app/models/location.model';

@Injectable({ providedIn: 'root' })
export class LocationDeleteDialogService {
  private isOpen = false;

  constructor(private modalService: NgbModal) {
  }

  open(location: ILocation): NgbModalRef {
    this.isOpen = true;
    const modalRef = this.modalService.open(LocationDeleteDialogComponent as Component, {
      size: 'lg',
      backdrop: 'static',
    });
    modalRef.componentInstance.location = location;
    modalRef.result.then(
      () => {
        this.isOpen = false;
      },
      () => {
        this.isOpen = false;
      }
    );
    return modalRef;
  }
}
