<div class="content-wrapper container-xxl p-0">
  <div class="content-body">

    <div class="row" id="table-hover-row">
      <div class="col-12">
        <core-card [actions]="[]" [reloadTime]="'1000'">
          <h4 class="card-title">
            <div>Liste des utilisateurs</div>
            <div (click)="updateUser($event)">
              <i data-feather="plus" class="mr-50 cursor-style"></i>
            </div>
          </h4>
          <div *ngIf="users && users.length === 0" class="no-data">
            <div class="no-data-text">
              Aucune donnée trouvée
            </div>
          </div>
          <div class="table-responsive mb-1" *ngIf="users && users.length > 0">
            <table class="table table-hover">
              <thead>
                <tr jhiSort [(predicate)]="predicate" [(ascending)]="ascending" (sortChange)="transition()">
                  <th scope="col" jhiSortBy="login">
                    Utilisateur <fa-icon icon="sort"></fa-icon>
                  </th>
                  <th scope="col" jhiSortBy="email">
                    Email <fa-icon icon="sort"></fa-icon>
                  </th>
                  <th scope="col">Statut</th>
                  <th scope="col">Privilège</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody *ngIf="users">
                <tr *ngFor="let user of users; trackBy: trackIdentity">
                  <td>{{ user.login }}</td>
                  <td>{{ user.email }}</td>
                  <td>
                    <button class="btn btn-danger btn-sm" (click)="setActive(user, true)" *ngIf="!user.activated">
                      Désactivé
                    </button>
                    <button class="btn btn-success btn-sm" (click)="setActive(user, false)" *ngIf="user.activated"
                      [disabled]="!currentAccount || currentAccount.login === user.login">
                      Activé
                    </button>
                  </td>
                  <td>
                    <div *ngFor="let authority of user.authorities">
                      <span class="badge bg-info">{{ authority }}</span>
                    </div>
                  </td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem (click)="updateUser(user)">
                          <i data-feather="edit-2" class="mr-50"></i>
                          <span>Modifier</span>
                        </a>
                        <a ngbDropdownItem (click)="deleteUser(user)" *ngIf="currentAccount && currentAccount.login !== user.login">
                          <i data-feather="trash" class="mr-50"></i>
                          <span>Supprimer</span>
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="users">
            <div class="d-flex justify-content-center">
              <jhi-item-count [params]="{ page: page, totalItems: totalItems, itemsPerPage: itemsPerPage }">
              </jhi-item-count>
            </div>

            <div class="d-flex justify-content-center">
              <ngb-pagination [collectionSize]="totalItems" [(page)]="page" [pageSize]="itemsPerPage" [maxSize]="5"
                [rotate]="true" [boundaryLinks]="true" (pageChange)="transition()"></ngb-pagination>
            </div>
          </div>
        </core-card>
      </div>
    </div>
  </div>