import { HttpResponse } from '@angular/common/http';
import { ThrowStmt } from '@angular/compiler';
import { ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core'
import { CoreConfigService } from '@core/services/config.service';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import { colors } from 'app/colors.const';
import { IAlert } from 'app/models/alert.model';
import { IDevice } from 'app/models/device.model';
import { ILocation } from 'app/models/location.model';
import { IMeasure } from 'app/models/measure.model';
import { ILocationTracker, LocationTracker } from 'app/models/measure.model copy';
import { AlertService } from 'app/services/alert.service';
import { DataStoreService } from 'app/services/data-store.service';
import { DeviceService } from 'app/services/device.service';
import { LocationService } from 'app/services/location.service';
import { MeasureService } from 'app/services/measure.service';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @ViewChild('fmiChartRef') fmiChartRef: any;

  @ViewChild('firstMeasureChartRef') firstMeasureChartRef: any;
  @ViewChild('secondMeasureChartRef') secondMeasureChartRef: any;
  @ViewChild('thirdMeasureChartRef') thirdMeasureChartRef: any;
  @ViewChild('fourthMeasureChartRef') fourthMeasureChartRef: any;
  @ViewChild('fifthMeasureChartRef') fifthMeasureChartRef: any;
  @ViewChild('sixthMeasureChartRef') sixthMeasureChartRef: any;
  @ViewChild('seventhMeasureChartRef') seventhMeasureChartRef: any;

  public intervalId: any;

  public fmiChartOptions: any;

  public firstMeasureChartOptions: any;
  public secondMeasureChartOptions: any;
  public thirdMeasureChartOptions: any;
  public fourthMeasureChartOptions: any;
  public fifthMeasureChartOptions: any;
  public sixthMeasureChartOptions: any;
  public seventhMeasureChartOptions: any;

  private fmiPrimaryStrokeColor = '#7368F0';
  private fmiSecondaryStrokeColor = '#C7C3F9';

  private firstMeasurePrimaryStrokeColor = '#fff';
  private firstMeasureSecondaryStrokeColor = '#5e5873';

  private secondMeasurePrimaryStrokeColor = '#00B2CE';
  private secondMeasureSecondaryStrokeColor = '#85EEFF';

  private thirdMeasurePrimaryStrokeColor = '#28C76F';
  private thirdMeasureSecondaryStrokeColor = '#A5EDC5';

  private fourthMeasurePrimaryStrokeColor = '#FF9F43';
  private fourthMeasureSecondaryStrokeColor = '#FFD9B4';

  private fifthMeasurePrimaryStrokeColor = '#00B2CE';
  private fifthMeasureSecondaryStrokeColor = '#85EEFF';

  private sixthMeasurePrimaryStrokeColor = '#00B2CE';
  private sixthMeasureSecondaryStrokeColor = '#85EEFF';

  private seventhMeasurePrimaryStrokeColor = '#fff';
  private seventhMeasureSecondaryStrokeColor = '#5e5873';

  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;
  pauseOnFocus = true;

  ambianceHallTracker?: Map<String, IMeasure> | null;
  fmiTracker?: Map<String, IMeasure> | null;
  locationsTracker?: Map<number, ILocationTracker> | null;
  alerts?: IAlert[];

  @ViewChild('carousel', { static: true }) carousel: NgbCarousel;

  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }

  constructor(
    protected locationService: LocationService,
    protected deviceService: DeviceService,
    protected measureService: MeasureService,
    protected alertService: AlertService,
    public dataStore: DataStoreService,
    private cdref: ChangeDetectorRef,
    private _coreConfigService: CoreConfigService
  ) {
    // FMI Chart
    this.fmiChartOptions = {
      chart: {
        type: 'donut',
        height: 120,
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      series: [36, 64],
      legend: { show: false },
      comparedResult: [2, -3, 8],
      labels: ['Rempli', 'Vide'],
      stroke: { width: 0 },
      colors: [this.fmiPrimaryStrokeColor, this.fmiSecondaryStrokeColor],
      grid: {
        padding: {
          right: -20,
          bottom: -8,
          left: -20
        }
      },
      plotOptions: {
        pie: {
          startAngle: -10,
          donut: {
            labels: {
              show: true,
              name: {
                offsetY: 15
              },
              value: {
                offsetY: -15,
                formatter: function (val) {
                  return parseInt(val) + '%';
                }
              },
              total: {
                show: true,
                offsetY: 15,
                label: '/400',
                formatter: function (w) {
                  return '145';
                }
              }
            }
          }
        }
      },
      responsive: [
        {
          breakpoint: 1325,
          options: {
            chart: {
              height: 100
            }
          }
        },
        {
          breakpoint: 1200,
          options: {
            chart: {
              height: 120
            }
          }
        },
        {
          breakpoint: 1065,
          options: {
            chart: {
              height: 100
            }
          }
        },
        {
          breakpoint: 992,
          options: {
            chart: {
              height: 120
            }
          }
        }
      ]
    };

    // First Measure Chart
    let maxValue = 50;
    let minValue = 0;

    this.firstMeasureChartOptions = {
      chart: {
        height: 290,
        type: 'radialBar',
        sparkline: {
          enabled: false
        }
      },
      plotOptions: {
        radialBar: {
          offsetY: 20,
          startAngle: -150,
          endAngle: 150,
          hollow: {
            size: '64%'
          },
          track: {
            background: this.firstMeasurePrimaryStrokeColor,
            strokeWidth: '100%'
          },
          dataLabels: {
            name: {
              offsetY: -5,
              color: this.firstMeasureSecondaryStrokeColor,
              fontSize: '0.9rem'
            },
            value: {
              offsetY: 15,
              color: this.firstMeasureSecondaryStrokeColor,
              fontSize: '1.4rem',
              formatter: function (val: number) {
                return (val * (maxValue - minValue)) / 100 + minValue + ' °C';
              }
            }
          }
        }
      },
      colors: [colors.solid.danger],
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: [colors.solid.primary],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [70, 100]
        }
      },
      stroke: {
        dashArray: 8
      },
      labels: ['Température']
    };

    // Fifth Measure Chart
    this.fifthMeasureChartOptions = {
      chart: {
        height: 290,
        type: 'radialBar',
        sparkline: {
          enabled: false
        }
      },
      plotOptions: {
        radialBar: {
          offsetY: 20,
          startAngle: -150,
          endAngle: 150,
          hollow: {
            size: '64%'
          },
          track: {
            background: this.firstMeasurePrimaryStrokeColor,
            strokeWidth: '100%'
          },
          dataLabels: {
            name: {
              offsetY: -5,
              color: this.firstMeasureSecondaryStrokeColor,
              fontSize: '0.9rem'
            },
            value: {
              offsetY: 15,
              color: this.firstMeasureSecondaryStrokeColor,
              fontSize: '1.4rem',
              formatter: function (val: number) {
                return '12 kWh';
              }
            }
          }
        }
      },
      colors: [colors.solid.danger],
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: [colors.solid.primary],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [70, 100]
        }
      },
      stroke: {
        dashArray: 8
      },
      labels: ['Électricité']
    };

    // Fifth Measure Chart
    this.sixthMeasureChartOptions = {
      chart: {
        height: 290,
        type: 'radialBar',
        sparkline: {
          enabled: false
        }
      },
      plotOptions: {
        radialBar: {
          offsetY: 20,
          startAngle: -150,
          endAngle: 150,
          hollow: {
            size: '64%'
          },
          track: {
            background: this.firstMeasurePrimaryStrokeColor,
            strokeWidth: '100%'
          },
          dataLabels: {
            name: {
              offsetY: -5,
              color: this.firstMeasureSecondaryStrokeColor,
              fontSize: '0.9rem'
            },
            value: {
              offsetY: 15,
              color: this.firstMeasureSecondaryStrokeColor,
              fontSize: '1.4rem',
              formatter: function (val: number) {
                return '15 kWh';
              }
            }
          }
        }
      },
      colors: [colors.solid.danger],
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: [colors.solid.primary],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [70, 100]
        }
      },
      stroke: {
        dashArray: 8
      },
      labels: ['Gaz']
    };

    // Second Measure Chart
    this.secondMeasureChartOptions = {
      chart: {
        type: 'donut',
        height: 100,
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      series: [67, 33],
      legend: { show: false },
      comparedResult: [2, -3, 8],
      labels: ['H2O', 'H2O'],
      stroke: { width: 0 },
      colors: [this.secondMeasurePrimaryStrokeColor, this.secondMeasureSecondaryStrokeColor],
      grid: {
        padding: {
          right: -20,
          bottom: -8,
          left: -20
        }
      },
      plotOptions: {
        pie: {
          startAngle: -10,
          donut: {
            labels: {
              show: true,
              name: {
                offsetY: 15
              },
              value: {
                offsetY: -15,
                formatter: function (val: string) {
                  return parseInt(val) + '%';
                }
              },
              total: {
                show: true,
                offsetY: 15,
                label: 'H2O',
                formatter: function (w: any) {
                  return '67%';
                }
              }
            }
          }
        }
      },
      responsive: [
        {
          breakpoint: 1325,
          options: {
            chart: {
              height: 100
            }
          }
        },
        {
          breakpoint: 1200,
          options: {
            chart: {
              height: 120
            }
          }
        },
        {
          breakpoint: 1065,
          options: {
            chart: {
              height: 100
            }
          }
        },
        {
          breakpoint: 992,
          options: {
            chart: {
              height: 120
            }
          }
        }
      ]
    };

    // Third Measure Chart
    this.thirdMeasureChartOptions = {
      chart: {
        type: 'donut',
        height: 100,
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      series: [40, 60],
      legend: { show: false },
      comparedResult: [2, -3, 8],
      labels: ['Cl', 'Cl'],
      stroke: { width: 0 },
      colors: [this.thirdMeasurePrimaryStrokeColor, this.thirdMeasureSecondaryStrokeColor],
      grid: {
        padding: {
          right: -20,
          bottom: -8,
          left: -20
        }
      },
      plotOptions: {
        pie: {
          startAngle: -10,
          donut: {
            labels: {
              show: true,
              name: {
                offsetY: 15
              },
              value: {
                offsetY: -15,
                formatter: function (val: string) {
                  return parseInt(val) + '%';
                }
              },
              total: {
                show: true,
                offsetY: 15,
                label: 'Cl',
                formatter: function (w: any) {
                  return '2.3';
                }
              }
            }
          }
        }
      },
      responsive: [
        {
          breakpoint: 1325,
          options: {
            chart: {
              height: 100
            }
          }
        },
        {
          breakpoint: 1200,
          options: {
            chart: {
              height: 120
            }
          }
        },
        {
          breakpoint: 1065,
          options: {
            chart: {
              height: 100
            }
          }
        },
        {
          breakpoint: 992,
          options: {
            chart: {
              height: 120
            }
          }
        }
      ]
    };

    // Fourth Measure Chart
    this.fourthMeasureChartOptions = {
      chart: {
        type: 'donut',
        height: 100,
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      series: [47, 53],
      legend: { show: false },
      comparedResult: [2, -3, 8],
      labels: ['pH', 'PH'],
      stroke: { width: 0 },
      colors: [this.fourthMeasurePrimaryStrokeColor, this.fourthMeasureSecondaryStrokeColor],
      grid: {
        padding: {
          right: -20,
          bottom: -8,
          left: -20
        }
      },
      plotOptions: {
        pie: {
          startAngle: -10,
          donut: {
            labels: {
              show: true,
              name: {
                offsetY: 15
              },
              value: {
                offsetY: -15,
                formatter: function (val: string) {
                  return parseInt(val) + '%';
                }
              },
              total: {
                show: true,
                offsetY: 15,
                label: 'pH',
                formatter: function (w: any) {
                  return '7.4';
                }
              }
            }
          }
        }
      },
      responsive: [
        {
          breakpoint: 1325,
          options: {
            chart: {
              height: 100
            }
          }
        },
        {
          breakpoint: 1200,
          options: {
            chart: {
              height: 120
            }
          }
        },
        {
          breakpoint: 1065,
          options: {
            chart: {
              height: 100
            }
          }
        },
        {
          breakpoint: 992,
          options: {
            chart: {
              height: 120
            }
          }
        }
      ]
    };

    // Seventh Measure Chart
    let startAngleValue = -150;
    let endAngleValue = 150;

    this.seventhMeasureChartOptions = {
      chart: {
        height: 290,
        type: 'radialBar',
        sparkline: {
          enabled: false
        }
      },
      plotOptions: {
        radialBar: {
          offsetY: 20,
          startAngle: startAngleValue,
          endAngle: endAngleValue,
          hollow: {
            size: '65%'
          },
          track: {
            background: this.seventhMeasurePrimaryStrokeColor,
            strokeWidth: '100%'
          },
          dataLabels: {
            name: {
              offsetY: -5,
              color: this.seventhMeasureSecondaryStrokeColor,
              fontSize: '1rem'
            },
            value: {
              offsetY: 15,
              color: this.seventhMeasureSecondaryStrokeColor,
              fontSize: '1.714rem',
              formatter: function (val: number) {
                return (val * (maxValue - minValue)) / 100 + minValue + ' °C';
              }
            }
          }
        }
      },
      colors: [colors.solid.danger],
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: [colors.solid.primary],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [50]
        }
      },
      stroke: {
        dashArray: 8
      },
      labels: ['Température de l\'eau']
    };
  }

  ngOnInit() {
    this.ambianceHallTracker = new Map<String, IMeasure>();
    this.fmiTracker = new Map<String, IMeasure>();
    this.locationsTracker = new Map<number, ILocationTracker>();

    this.loadData();
    this.intervalId = setInterval(() => {
      this.loadData();
    }, 30000);
    this.loadScriptByUrl('https://weatherwidget.io/js/widget.min.js');
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  loadData() {
    this.alertService
      .findAllByHidden(
        {
          size: 500,
        },
        false
      )
      .subscribe(
        {
          next: (res: HttpResponse<IAlert[]>) => {
            this.alerts = res.body ?? [];
          },
          error: (err) => { },
        }
      );

    this.locationService
      .query(
        {
          size: 1000,
        }
      ).subscribe(
        {
          next: async (res: HttpResponse<ILocation[]>) => {

            let locations = res.body ?? [];

            const promises = locations.map(async (location: any) => {

              this.deviceService
                .findAllByByLocationId(
                  {
                    size: 1000,
                  },
                  location.id
                )
                .subscribe(
                  {
                    next: async (res: HttpResponse<IDevice[]>) => {

                      location.devices = res.body ?? [];
                      let measures = new Map<String, IMeasure>();

                      const promises = location.devices.map(async (device: any) => this.measureService
                        .findAllByByDeviceId(
                          {
                            size: 1000,
                          },
                          device.id
                        )
                        .subscribe(
                          {
                            next: (res: HttpResponse<IMeasure[]>) => {
                              res.body.map(measure => {
                                measures.set(measure.name, measure);
                              });
                            },
                            error: () => { },
                          }
                        ));

                      await Promise.all(promises);

                      if (location.id == 1) {
                        this.ambianceHallTracker = measures;
                      } else if (location.id == 2) {
                        this.fmiTracker = measures;
                      } else {
                        let locationTracker = new LocationTracker();
                        locationTracker.location = location;
                        locationTracker.measures = measures;
                        this.locationsTracker.set(location.id, locationTracker);
                      }
                    },
                    error: () => { },
                  }
                );

            });

            await Promise.all(promises);
          },
          error: () => { },
        }
      );
  }

  hideAlert(alert: IAlert): void {
    if (alert.id != undefined) {
      alert.hidden = true;

      this.subscribeToSaveResponse(this.alertService.update(alert));
    }
  }

  protected subscribeToSaveResponse(result: Observable<HttpResponse<IAlert>>): void {
    result.pipe(finalize(() => this.onSaveFinalize())).subscribe(
      () => this.onSaveSuccess(),
      () => this.onSaveError()
    );
  }

  protected onSaveSuccess(): void {
    this.loadData();
  }

  protected onSaveError(): void { }

  protected onSaveFinalize(): void { }

  loadScriptByUrl(url: string) {
    let dynamicScript = document.createElement('script');
    dynamicScript.type = 'text/javascript';
    dynamicScript.async = true;
    dynamicScript.src = url;
    document.body.appendChild(dynamicScript);
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  /**
 * After View Init
 */
  ngAfterViewInit() {
    // Subscribe to core config changes
    this._coreConfigService.getConfig().subscribe(config => {
      // If Menu Collapsed Changes
      if (config.layout.menu.collapsed === true || config.layout.menu.collapsed === false) {
        setTimeout(() => {
          // Get Dynamic Width for Charts
          this.firstMeasureChartOptions.chart.width = this.firstMeasureChartRef?.nativeElement.offsetWidth;
          this.secondMeasureChartOptions.chart.width = this.secondMeasureChartRef?.nativeElement.offsetWidth;
          this.thirdMeasureChartOptions.chart.width = this.thirdMeasureChartRef?.nativeElement.offsetWidth;
          this.fourthMeasureChartOptions.chart.width = this.fourthMeasureChartRef?.nativeElement.offsetWidth;
          this.fifthMeasureChartOptions.chart.width = this.fifthMeasureChartRef?.nativeElement.offsetWidth;
          this.sixthMeasureChartOptions.chart.width = this.sixthMeasureChartRef?.nativeElement.offsetWidth;
          this.seventhMeasureChartOptions.chart.width = this.seventhMeasureChartRef?.nativeElement.offsetWidth;
          this.fmiChartOptions.chart.width = this.fmiChartRef?.nativeElement.offsetWidth;
        }, 1000);
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.firstMeasureChartOptions.chart.width = this.firstMeasureChartRef?.nativeElement.offsetWidth;
    this.secondMeasureChartOptions.chart.width = this.secondMeasureChartRef?.nativeElement.offsetWidth;
    this.thirdMeasureChartOptions.chart.width = this.thirdMeasureChartRef?.nativeElement.offsetWidth;
    this.fourthMeasureChartOptions.chart.width = this.fourthMeasureChartRef?.nativeElement.offsetWidth;
    this.fifthMeasureChartOptions.chart.width = this.fifthMeasureChartRef?.nativeElement.offsetWidth;
    this.sixthMeasureChartOptions.chart.width = this.sixthMeasureChartRef?.nativeElement.offsetWidth;
    this.seventhMeasureChartOptions.chart.width = this.seventhMeasureChartRef?.nativeElement.offsetWidth;
    this.fmiChartOptions.chart.width = this.fmiChartRef?.nativeElement.offsetWidth;
  }
}
