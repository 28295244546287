import { ILocation } from './location.model';
import { IMeasure } from './measure.model';

export interface ILocationTracker {
  location?: ILocation | null;
  measures?: Map<String, IMeasure> | null;
}

export class LocationTracker implements ILocationTracker {
  constructor(
    public location?: ILocation | null,
    public measures?: Map<String, IMeasure> | null
  ) {}
}