import { IMeasure } from 'app/models/measure.model';
import { ILocation } from 'app/models/location.model';

export interface IDevice {
  id?: number;
  name?: string | null;
  code?: string | null;
  deviceID?: string | null;
  devEUI?: string | null;
  measures?: IMeasure[] | null;
  location?: ILocation | null;
}

export class Device implements IDevice {
  constructor(
    public id?: number,
    public name?: string | null,
    public code?: string | null,
    public deviceID?: string | null,
    public devEUI?: string | null,
    public measures?: IMeasure[] | null,
    public location?: ILocation | null
  ) {}
}

export function getDeviceIdentifier(device: IDevice): number | undefined {
  return device.id;
}
