<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- Apexchart Line Chart Component-->
    <section id="apex-line-chart">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div
              class="card-header d-flex flex-sm-row flex-column justify-content-md-between align-items-start justify-content-start">
              <div>
                <h4 class="card-title">Line Chart</h4>
              </div>
            </div>
            <div class="card-body">
              <div id="apexLineChartId" #apexLineAreaChartRef>
                <apx-chart [series]="apexLineAreaChart.series" [chart]="{
                        height: 400,
                        width: isMenuToggled === false ? apexLineAreaChartRef.offsetWidth : apexLineAreaChart.chart.width,
                        type: 'area',
                        stacked: true,
                        zoom: {
                          enabled: false
                        },
                        toolbar: {
                          show: false
                        }
                      }" [fill]="apexLineAreaChart.fill" [xaxis]="apexLineAreaChart.xaxis"
                  [dataLabels]="apexLineAreaChart.dataLabels" [grid]="apexLineAreaChart.grid"
                  [tooltip]="apexLineAreaChart.tooltip" [stroke]="apexLineAreaChart.stroke"
                  [colors]="apexLineAreaChart.colors" [legend]="apexLineAreaChart.legend"></apx-chart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--/ Apexchart Line Chart Component-->
  </div>
</div>