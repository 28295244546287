<form *ngIf="user" name="deleteForm" (ngSubmit)="confirmDelete(user.login!)">
  <div class="modal-header">
    <h4 class="modal-title" data-cy="userDeleteDialogHeading">Confirmation de suppression</h4>
  </div>

  <div class="modal-body">
    <p id="delete-location-heading">
      Êtes-vous certain de vouloir supprimer cet utilisateur ?
    </p>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancel()">
      <span>Annuler</span>
    </button>

    <button id="confirm-delete-user" data-cy="entityConfirmDeleteButton" type="submit" class="btn btn-danger">
      <span>Supprimer</span>
    </button>
  </div>
</form>