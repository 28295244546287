<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <div class="row match-height">
      <!-- Meteo Card -->
      <div class="col-xl-12 col-md-12 col-sm-12 col-12">
        <div class="card card-user-timeline">
          <a class="weatherwidget-io" href="https://forecast7.com/fr/48d862d35/paris/" data-label_1="Paris"
            data-label_2="France" data-days="5" data-theme="original">Paris France</a>
        </div>
      </div>
      <!--/ Meteo Card -->
    </div>

    <div class="row match-height">
      <!-- Alerts Card -->
      <div class="col-xl-12 col-md-12 col-sm-12 col-12">
        <div class="card card-user-timeline">
          <div class="card-header">
            <div class="d-flex align-items-center">
              <i data-feather="bell" class="user-timeline-title-icon"></i>
              <h4 class="card-title">Alertes</h4>
            </div>
            <div class="d-flex align-items-center">
              <p class="card-text font-small-2 mr-25 mb-0">4 alertes</p>
            </div>
          </div>
          <!-- <div class="card-body alerts-container" *ngIf="alerts && alerts.length === 0">
            <div class="no-data-text">
              Aucune alerte reçue
            </div>
          </div> -->
          <div class="card-body alerts-container">
            <ul class="timeline ml-50 mb-0">
              <li class="timeline-item">
                <span class="timeline-point timeline-point-danger timeline-point-indicator"></span>
                <div class="timeline-event">
                  <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                    <h6>Jeu 22 août 22 13:00 Alerte bassin exterieur, température (39), supérieur à la limite</h6>
                  </div>
                </div>
              </li>
              <li class="timeline-item">
                <span class="timeline-point timeline-point-info timeline-point-indicator"></span>
                <div class="timeline-event">
                  <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                    <h6>Mer 21 août 22 45:00 Alerte FMI, limite supérieur atteinte (400)</h6>
                  </div>
                </div>
              </li>
              <li class="timeline-item">
                <span class="timeline-point timeline-point-danger timeline-point-indicator"></span>
                <div class="timeline-event">
                  <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                    <h6>Mar 20 août 22 14:50 Alerte bassin enfants, PH (8,2) supérieur à la limite</h6>
                  </div>
                </div>
              </li>
              <li class="timeline-item">
                <span class="timeline-point timeline-point-warning timeline-point-indicator"></span>
                <div class="timeline-event">
                  <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                    <h6>Mar 20 août 22 11:30 Alerte bassin enfants, PH (7,2) supérieur à la limite</h6>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--/ Alerts Card -->
    </div>

    <div class="row match-height">
      <div class="col-xl-7 col-md-12 col-sm-12 col-12">
        <div class="card earnings-card card-user-timeline">
          <ngb-carousel #carousel [interval]="3000" [pauseOnHover]="pauseOnHover" [pauseOnFocus]="pauseOnFocus"
            (slide)="onSlide($event)">
            <ng-template ngbSlide>
              <div class="picsum-img-wrapper">
                <div class="card-header">
                  <div class="d-flex align-items-center">
                    <i data-feather="pie-chart" class="user-timeline-title-icon"></i>
                    <h4 class="card-title">Bassin Intérieur</h4>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row pb-50">
                    <div
                      class="col-lg-6 col-12 d-flex justify-content-between flex-column order-lg-1 order-2 mt-1 mt-lg-0">
                      <div class="div2">
                        <div class="div3" id="fmi-donut-chart" #secondMeasureChartRef>
                          <apx-chart [chart]="{
                            type: 'donut',
                            width: secondMeasureChartRef.offsetWidth,
                            height: 100,
                            toolbar: {
                              show: false
                            }
                          }" [dataLabels]="secondMeasureChartOptions.dataLabels"
                            [series]="secondMeasureChartOptions.series" [legend]="secondMeasureChartOptions.legend"
                            [labels]="secondMeasureChartOptions.labels" [stroke]="secondMeasureChartOptions.stroke"
                            [colors]="secondMeasureChartOptions.colors" [grid]="secondMeasureChartOptions.grid"
                            [plotOptions]="secondMeasureChartOptions.plotOptions">
                          </apx-chart>
                        </div>
                        <div class="div4" id="fmi-donut-chart" #thirdMeasureChartRef>
                          <apx-chart [chart]="{
                            type: 'donut',
                            width: thirdMeasureChartRef.offsetWidth,
                            height: 100,
                            toolbar: {
                              show: false
                            }
                          }" [dataLabels]="thirdMeasureChartOptions.dataLabels"
                            [series]="thirdMeasureChartOptions.series" [legend]="thirdMeasureChartOptions.legend"
                            [labels]="thirdMeasureChartOptions.labels" [stroke]="thirdMeasureChartOptions.stroke"
                            [colors]="thirdMeasureChartOptions.colors" [grid]="thirdMeasureChartOptions.grid"
                            [plotOptions]="thirdMeasureChartOptions.plotOptions">
                          </apx-chart>
                        </div>
                      </div>
                      <div class="div1" id="fmi-donut-chart" #fourthMeasureChartRef>
                        <apx-chart [chart]="{
                          type: 'donut',
                          width: fourthMeasureChartRef.offsetWidth,
                          height: 100,
                          toolbar: {
                            show: false
                          }
                        }" [dataLabels]="fourthMeasureChartOptions.dataLabels"
                          [series]="fourthMeasureChartOptions.series" [legend]="fourthMeasureChartOptions.legend"
                          [labels]="fourthMeasureChartOptions.labels" [stroke]="fourthMeasureChartOptions.stroke"
                          [colors]="fourthMeasureChartOptions.colors" [grid]="fourthMeasureChartOptions.grid"
                          [plotOptions]="fourthMeasureChartOptions.plotOptions">
                        </apx-chart>
                      </div>
                    </div>

                    <div
                      class="col-lg-6 col-12 d-flex justify-content-between flex-column text-right order-lg-2 order-1 earnings-card">
                      <div class="div2">
                        <div class="div3" id="firstMeasureChartRef" #firstMeasureChartRef>
                          <apx-chart [series]="['74']" [chart]="{
                              height: 210,
                              width: firstMeasureChartRef.offsetWidth,
                              type: 'radialBar',
                              sparkline: {
                                enabled: false
                              }
                            }" [plotOptions]="firstMeasureChartOptions.plotOptions"
                            [colors]="firstMeasureChartOptions.colors" [fill]="firstMeasureChartOptions.fill"
                            [stroke]="firstMeasureChartOptions.stroke" [labels]="firstMeasureChartOptions.labels">
                          </apx-chart>
                        </div>
                      </div>

                      <div class="div2">
                        <div class="div3" id="fifthMeasureChartRef" #fifthMeasureChartRef>
                          <apx-chart [series]="['44']" [chart]="{
                            height: 210,
                            width: fifthMeasureChartRef.offsetWidth,
                            type: 'radialBar',
                            sparkline: {
                              enabled: false
                            }
                          }" [plotOptions]="fifthMeasureChartOptions.plotOptions"
                            [colors]="fifthMeasureChartOptions.colors" [fill]="fifthMeasureChartOptions.fill"
                            [stroke]="fifthMeasureChartOptions.stroke" [labels]="fifthMeasureChartOptions.labels">
                          </apx-chart>
                        </div>
                        <div class="div3" id="sixthMeasureChartRef" #sixthMeasureChartRef>
                          <apx-chart [series]="['64']" [chart]="{
                            height: 210,
                            width: sixthMeasureChartRef.offsetWidth,
                            type: 'radialBar',
                            sparkline: {
                              enabled: false
                            }
                          }" [plotOptions]="sixthMeasureChartOptions.plotOptions"
                            [colors]="sixthMeasureChartOptions.colors" [fill]="sixthMeasureChartOptions.fill"
                            [stroke]="sixthMeasureChartOptions.stroke" [labels]="sixthMeasureChartOptions.labels">
                          </apx-chart>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />
                  <div class="row avg-sessions pt-50">
                    <div class="col-12">
                      <p class="mb-50 progressbar-text">
                        <span>Température extérieure:</span>
                        <span class="metric-font">7,7 °C</span>
                      </p>

                      <ngb-progressbar type="warning" [height]="'6px'" [value]="50"></ngb-progressbar>
                    </div>
                  </div>
                  <div class="row avg-sessions pt-50">
                    <div class="col-12">
                      <p class="mb-50 progressbar-text">
                        <span>Ressentie:</span>
                        <span class="metric-font">8.1 °C</span>
                      </p>

                      <ngb-progressbar type="success" [height]="'6px'" [value]="50"></ngb-progressbar>
                    </div>
                  </div>
                  <div class="row avg-sessions pt-50">
                    <div class="col-12">
                      <p class="mb-50 progressbar-text">
                        <span>H2O:</span>
                        <span class="metric-font">90%</span>
                      </p>

                      <ngb-progressbar type="primary" [height]="'6px'" [value]="50"></ngb-progressbar>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>

            <ng-template ngbSlide>
              <div class="picsum-img-wrapper">
                <div class="card-header">
                  <div class="d-flex align-items-center">
                    <i data-feather="pie-chart" class="user-timeline-title-icon"></i>
                    <h4 class="card-title">Bassin Extérieur</h4>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row pb-50">
                    <div
                      class="col-lg-6 col-12 d-flex justify-content-between flex-column order-lg-1 order-2 mt-1 mt-lg-0">
                      <div class="div2">
                        <div class="div3" id="fmi-donut-chart" #secondMeasureChartRef>
                          <apx-chart [chart]="{
                            type: 'donut',
                            width: secondMeasureChartRef.offsetWidth,
                            height: 100,
                            toolbar: {
                              show: false
                            }
                          }" [dataLabels]="secondMeasureChartOptions.dataLabels"
                            [series]="secondMeasureChartOptions.series" [legend]="secondMeasureChartOptions.legend"
                            [labels]="secondMeasureChartOptions.labels" [stroke]="secondMeasureChartOptions.stroke"
                            [colors]="secondMeasureChartOptions.colors" [grid]="secondMeasureChartOptions.grid"
                            [plotOptions]="secondMeasureChartOptions.plotOptions">
                          </apx-chart>
                        </div>
                        <div class="div4" id="fmi-donut-chart" #thirdMeasureChartRef>
                          <apx-chart [chart]="{
                            type: 'donut',
                            width: thirdMeasureChartRef.offsetWidth,
                            height: 100,
                            toolbar: {
                              show: false
                            }
                          }" [dataLabels]="thirdMeasureChartOptions.dataLabels"
                            [series]="thirdMeasureChartOptions.series" [legend]="thirdMeasureChartOptions.legend"
                            [labels]="thirdMeasureChartOptions.labels" [stroke]="thirdMeasureChartOptions.stroke"
                            [colors]="thirdMeasureChartOptions.colors" [grid]="thirdMeasureChartOptions.grid"
                            [plotOptions]="thirdMeasureChartOptions.plotOptions">
                          </apx-chart>
                        </div>
                      </div>
                      <div class="div1" id="fmi-donut-chart" #fourthMeasureChartRef>
                        <apx-chart [chart]="{
                          type: 'donut',
                          width: fourthMeasureChartRef.offsetWidth,
                          height: 100,
                          toolbar: {
                            show: false
                          }
                        }" [dataLabels]="fourthMeasureChartOptions.dataLabels"
                          [series]="fourthMeasureChartOptions.series" [legend]="fourthMeasureChartOptions.legend"
                          [labels]="fourthMeasureChartOptions.labels" [stroke]="fourthMeasureChartOptions.stroke"
                          [colors]="fourthMeasureChartOptions.colors" [grid]="fourthMeasureChartOptions.grid"
                          [plotOptions]="fourthMeasureChartOptions.plotOptions">
                        </apx-chart>
                      </div>
                    </div>

                    <div
                      class="col-lg-6 col-12 d-flex justify-content-between flex-column text-right order-lg-2 order-1 earnings-card">
                      <div class="div2">
                        <div class="div3" id="firstMeasureChartRef" #firstMeasureChartRef>
                          <apx-chart [series]="['74']" [chart]="{
                              height: 210,
                              width: firstMeasureChartRef.offsetWidth,
                              type: 'radialBar',
                              sparkline: {
                                enabled: false
                              }
                            }" [plotOptions]="firstMeasureChartOptions.plotOptions"
                            [colors]="firstMeasureChartOptions.colors" [fill]="firstMeasureChartOptions.fill"
                            [stroke]="firstMeasureChartOptions.stroke" [labels]="firstMeasureChartOptions.labels">
                          </apx-chart>
                        </div>
                      </div>

                      <div class="div2">
                        <div class="div3" id="fifthMeasureChartRef" #fifthMeasureChartRef>
                          <apx-chart [series]="['44']" [chart]="{
                            height: 210,
                            width: fifthMeasureChartRef.offsetWidth,
                            type: 'radialBar',
                            sparkline: {
                              enabled: false
                            }
                          }" [plotOptions]="fifthMeasureChartOptions.plotOptions"
                            [colors]="fifthMeasureChartOptions.colors" [fill]="fifthMeasureChartOptions.fill"
                            [stroke]="fifthMeasureChartOptions.stroke" [labels]="fifthMeasureChartOptions.labels">
                          </apx-chart>
                        </div>
                        <div class="div3" id="sixthMeasureChartRef" #sixthMeasureChartRef>
                          <apx-chart [series]="['64']" [chart]="{
                            height: 210,
                            width: sixthMeasureChartRef.offsetWidth,
                            type: 'radialBar',
                            sparkline: {
                              enabled: false
                            }
                          }" [plotOptions]="sixthMeasureChartOptions.plotOptions"
                            [colors]="sixthMeasureChartOptions.colors" [fill]="sixthMeasureChartOptions.fill"
                            [stroke]="sixthMeasureChartOptions.stroke" [labels]="sixthMeasureChartOptions.labels">
                          </apx-chart>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />
                  <div class="row avg-sessions pt-50">
                    <div class="col-12">
                      <p class="mb-50 progressbar-text">
                        <span>Température extérieure:</span>
                        <span class="metric-font">7,7 °C</span>
                      </p>

                      <ngb-progressbar type="warning" [height]="'6px'" [value]="50"></ngb-progressbar>
                    </div>
                  </div>
                  <div class="row avg-sessions pt-50">
                    <div class="col-12">
                      <p class="mb-50 progressbar-text">
                        <span>Ressentie:</span>
                        <span class="metric-font">8.1 °C</span>
                      </p>

                      <ngb-progressbar type="success" [height]="'6px'" [value]="50"></ngb-progressbar>
                    </div>
                  </div>
                  <div class="row avg-sessions pt-50">
                    <div class="col-12">
                      <p class="mb-50 progressbar-text">
                        <span>H2O:</span>
                        <span class="metric-font">90%</span>
                      </p>

                      <ngb-progressbar type="primary" [height]="'6px'" [value]="50"></ngb-progressbar>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>

            <ng-template ngbSlide>
              <div class="picsum-img-wrapper">
                <div class="card-header">
                  <div class="d-flex align-items-center">
                    <i data-feather="pie-chart" class="user-timeline-title-icon"></i>
                    <h4 class="card-title">Bassin Enfant</h4>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row pb-50">
                    <div
                      class="col-lg-6 col-12 d-flex justify-content-between flex-column order-lg-1 order-2 mt-1 mt-lg-0">
                      <div class="div2">
                        <div class="div3" id="fmi-donut-chart" #secondMeasureChartRef>
                          <apx-chart [chart]="{
                            type: 'donut',
                            width: secondMeasureChartRef.offsetWidth,
                            height: 100,
                            toolbar: {
                              show: false
                            }
                          }" [dataLabels]="secondMeasureChartOptions.dataLabels"
                            [series]="secondMeasureChartOptions.series" [legend]="secondMeasureChartOptions.legend"
                            [labels]="secondMeasureChartOptions.labels" [stroke]="secondMeasureChartOptions.stroke"
                            [colors]="secondMeasureChartOptions.colors" [grid]="secondMeasureChartOptions.grid"
                            [plotOptions]="secondMeasureChartOptions.plotOptions">
                          </apx-chart>
                        </div>
                        <div class="div4" id="fmi-donut-chart" #thirdMeasureChartRef>
                          <apx-chart [chart]="{
                            type: 'donut',
                            width: thirdMeasureChartRef.offsetWidth,
                            height: 100,
                            toolbar: {
                              show: false
                            }
                          }" [dataLabels]="thirdMeasureChartOptions.dataLabels"
                            [series]="thirdMeasureChartOptions.series" [legend]="thirdMeasureChartOptions.legend"
                            [labels]="thirdMeasureChartOptions.labels" [stroke]="thirdMeasureChartOptions.stroke"
                            [colors]="thirdMeasureChartOptions.colors" [grid]="thirdMeasureChartOptions.grid"
                            [plotOptions]="thirdMeasureChartOptions.plotOptions">
                          </apx-chart>
                        </div>
                      </div>
                      <div class="div1" id="fmi-donut-chart" #fourthMeasureChartRef>
                        <apx-chart [chart]="{
                          type: 'donut',
                          width: fourthMeasureChartRef.offsetWidth,
                          height: 100,
                          toolbar: {
                            show: false
                          }
                        }" [dataLabels]="fourthMeasureChartOptions.dataLabels"
                          [series]="fourthMeasureChartOptions.series" [legend]="fourthMeasureChartOptions.legend"
                          [labels]="fourthMeasureChartOptions.labels" [stroke]="fourthMeasureChartOptions.stroke"
                          [colors]="fourthMeasureChartOptions.colors" [grid]="fourthMeasureChartOptions.grid"
                          [plotOptions]="fourthMeasureChartOptions.plotOptions">
                        </apx-chart>
                      </div>
                    </div>

                    <div
                      class="col-lg-6 col-12 d-flex justify-content-between flex-column text-right order-lg-2 order-1 earnings-card">
                      <div class="div2">
                        <div class="div3" id="firstMeasureChartRef" #firstMeasureChartRef>
                          <apx-chart [series]="['74']" [chart]="{
                              height: 210,
                              width: firstMeasureChartRef.offsetWidth,
                              type: 'radialBar',
                              sparkline: {
                                enabled: false
                              }
                            }" [plotOptions]="firstMeasureChartOptions.plotOptions"
                            [colors]="firstMeasureChartOptions.colors" [fill]="firstMeasureChartOptions.fill"
                            [stroke]="firstMeasureChartOptions.stroke" [labels]="firstMeasureChartOptions.labels">
                          </apx-chart>
                        </div>
                      </div>

                      <div class="div2">
                        <div class="div3" id="fifthMeasureChartRef" #fifthMeasureChartRef>
                          <apx-chart [series]="['44']" [chart]="{
                            height: 210,
                            width: fifthMeasureChartRef.offsetWidth,
                            type: 'radialBar',
                            sparkline: {
                              enabled: false
                            }
                          }" [plotOptions]="fifthMeasureChartOptions.plotOptions"
                            [colors]="fifthMeasureChartOptions.colors" [fill]="fifthMeasureChartOptions.fill"
                            [stroke]="fifthMeasureChartOptions.stroke" [labels]="fifthMeasureChartOptions.labels">
                          </apx-chart>
                        </div>
                        <div class="div3" id="sixthMeasureChartRef" #sixthMeasureChartRef>
                          <apx-chart [series]="['64']" [chart]="{
                            height: 210,
                            width: sixthMeasureChartRef.offsetWidth,
                            type: 'radialBar',
                            sparkline: {
                              enabled: false
                            }
                          }" [plotOptions]="sixthMeasureChartOptions.plotOptions"
                            [colors]="sixthMeasureChartOptions.colors" [fill]="sixthMeasureChartOptions.fill"
                            [stroke]="sixthMeasureChartOptions.stroke" [labels]="sixthMeasureChartOptions.labels">
                          </apx-chart>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />
                  <div class="row avg-sessions pt-50">
                    <div class="col-12">
                      <p class="mb-50 progressbar-text">
                        <span>Température extérieure:</span>
                        <span class="metric-font">7,7 °C</span>
                      </p>

                      <ngb-progressbar type="warning" [height]="'6px'" [value]="50"></ngb-progressbar>
                    </div>
                  </div>
                  <div class="row avg-sessions pt-50">
                    <div class="col-12">
                      <p class="mb-50 progressbar-text">
                        <span>Ressentie:</span>
                        <span class="metric-font">8.1 °C</span>
                      </p>

                      <ngb-progressbar type="success" [height]="'6px'" [value]="50"></ngb-progressbar>
                    </div>
                  </div>
                  <div class="row avg-sessions pt-50">
                    <div class="col-12">
                      <p class="mb-50 progressbar-text">
                        <span>H2O:</span>
                        <span class="metric-font">90%</span>
                      </p>

                      <ngb-progressbar type="primary" [height]="'6px'" [value]="50"></ngb-progressbar>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>

            <ng-template ngbSlide>
              <div class="picsum-img-wrapper">
                <div class="card-header">
                  <div class="d-flex align-items-center">
                    <i data-feather="pie-chart" class="user-timeline-title-icon"></i>
                    <h4 class="card-title">SPA</h4>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row pb-50">
                    <div
                      class="col-lg-6 col-12 d-flex justify-content-between flex-column order-lg-1 order-2 mt-1 mt-lg-0">
                      <div class="div2">
                        <div class="div3" id="fmi-donut-chart" #secondMeasureChartRef>
                          <apx-chart [chart]="{
                            type: 'donut',
                            width: secondMeasureChartRef.offsetWidth,
                            height: 100,
                            toolbar: {
                              show: false
                            }
                          }" [dataLabels]="secondMeasureChartOptions.dataLabels"
                            [series]="secondMeasureChartOptions.series" [legend]="secondMeasureChartOptions.legend"
                            [labels]="secondMeasureChartOptions.labels" [stroke]="secondMeasureChartOptions.stroke"
                            [colors]="secondMeasureChartOptions.colors" [grid]="secondMeasureChartOptions.grid"
                            [plotOptions]="secondMeasureChartOptions.plotOptions">
                          </apx-chart>
                        </div>
                        <div class="div4" id="fmi-donut-chart" #thirdMeasureChartRef>
                          <apx-chart [chart]="{
                            type: 'donut',
                            width: thirdMeasureChartRef.offsetWidth,
                            height: 100,
                            toolbar: {
                              show: false
                            }
                          }" [dataLabels]="thirdMeasureChartOptions.dataLabels"
                            [series]="thirdMeasureChartOptions.series" [legend]="thirdMeasureChartOptions.legend"
                            [labels]="thirdMeasureChartOptions.labels" [stroke]="thirdMeasureChartOptions.stroke"
                            [colors]="thirdMeasureChartOptions.colors" [grid]="thirdMeasureChartOptions.grid"
                            [plotOptions]="thirdMeasureChartOptions.plotOptions">
                          </apx-chart>
                        </div>
                      </div>
                      <div class="div1" id="fmi-donut-chart" #fourthMeasureChartRef>
                        <apx-chart [chart]="{
                          type: 'donut',
                          width: fourthMeasureChartRef.offsetWidth,
                          height: 100,
                          toolbar: {
                            show: false
                          }
                        }" [dataLabels]="fourthMeasureChartOptions.dataLabels"
                          [series]="fourthMeasureChartOptions.series" [legend]="fourthMeasureChartOptions.legend"
                          [labels]="fourthMeasureChartOptions.labels" [stroke]="fourthMeasureChartOptions.stroke"
                          [colors]="fourthMeasureChartOptions.colors" [grid]="fourthMeasureChartOptions.grid"
                          [plotOptions]="fourthMeasureChartOptions.plotOptions">
                        </apx-chart>
                      </div>
                    </div>

                    <div
                      class="col-lg-6 col-12 d-flex justify-content-between flex-column text-right order-lg-2 order-1 earnings-card">
                      <div class="div2">
                        <div class="div3" id="firstMeasureChartRef" #firstMeasureChartRef>
                          <apx-chart [series]="['74']" [chart]="{
                              height: 210,
                              width: firstMeasureChartRef.offsetWidth,
                              type: 'radialBar',
                              sparkline: {
                                enabled: false
                              }
                            }" [plotOptions]="firstMeasureChartOptions.plotOptions"
                            [colors]="firstMeasureChartOptions.colors" [fill]="firstMeasureChartOptions.fill"
                            [stroke]="firstMeasureChartOptions.stroke" [labels]="firstMeasureChartOptions.labels">
                          </apx-chart>
                        </div>
                      </div>

                      <div class="div2">
                        <div class="div3" id="fifthMeasureChartRef" #fifthMeasureChartRef>
                          <apx-chart [series]="['44']" [chart]="{
                            height: 210,
                            width: fifthMeasureChartRef.offsetWidth,
                            type: 'radialBar',
                            sparkline: {
                              enabled: false
                            }
                          }" [plotOptions]="fifthMeasureChartOptions.plotOptions"
                            [colors]="fifthMeasureChartOptions.colors" [fill]="fifthMeasureChartOptions.fill"
                            [stroke]="fifthMeasureChartOptions.stroke" [labels]="fifthMeasureChartOptions.labels">
                          </apx-chart>
                        </div>
                        <div class="div3" id="sixthMeasureChartRef" #sixthMeasureChartRef>
                          <apx-chart [series]="['64']" [chart]="{
                            height: 210,
                            width: sixthMeasureChartRef.offsetWidth,
                            type: 'radialBar',
                            sparkline: {
                              enabled: false
                            }
                          }" [plotOptions]="sixthMeasureChartOptions.plotOptions"
                            [colors]="sixthMeasureChartOptions.colors" [fill]="sixthMeasureChartOptions.fill"
                            [stroke]="sixthMeasureChartOptions.stroke" [labels]="sixthMeasureChartOptions.labels">
                          </apx-chart>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />
                  <div class="row avg-sessions pt-50">
                    <div class="col-12">
                      <p class="mb-50 progressbar-text">
                        <span>Température extérieure:</span>
                        <span class="metric-font">7,7 °C</span>
                      </p>

                      <ngb-progressbar type="warning" [height]="'6px'" [value]="50"></ngb-progressbar>
                    </div>
                  </div>
                  <div class="row avg-sessions pt-50">
                    <div class="col-12">
                      <p class="mb-50 progressbar-text">
                        <span>Ressentie:</span>
                        <span class="metric-font">8.1 °C</span>
                      </p>

                      <ngb-progressbar type="success" [height]="'6px'" [value]="50"></ngb-progressbar>
                    </div>
                  </div>
                  <div class="row avg-sessions pt-50">
                    <div class="col-12">
                      <p class="mb-50 progressbar-text">
                        <span>H2O:</span>
                        <span class="metric-font">90%</span>
                      </p>

                      <ngb-progressbar type="primary" [height]="'6px'" [value]="50"></ngb-progressbar>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-carousel>
        </div>
      </div>

      <div class="col-xl-5 col-sm-12 col-12">
        <!-- Ambiance Hall Card -->
        <div class="card card-statistics card-user-timeline">
          <div class="card-header">
            <div class="d-flex align-items-center">
              <i data-feather="home" class="user-timeline-title-icon"></i>
              <h4 class="card-title">Ambiance Hall</h4>
            </div>
          </div>
          <div class="card-body statistics-body" *ngIf="this.ambianceHallTracker?.get('temperature') != undefined">
            <div class="row mb-1">
              <div class="col-xl-6 mb-1">
                <div class="media">
                  <div class="avatar bg-light-danger mr-2">
                    <div class="avatar-content">
                      <i data-feather="thermometer" class="avatar-icon"></i>
                    </div>
                  </div>
                  <div class="media-body my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      23.10 °C
                    </h4>
                    <p class="card-text font-small-3 mb-0">
                      Température
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-xl-6 mb-1">
                <div class="media">
                  <div class="avatar bg-light-info mr-2">
                    <div class="avatar-content">
                      <i data-feather="droplet" class="avatar-icon"></i>
                    </div>
                  </div>
                  <div class="media-body my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      48.5 %
                    </h4>
                    <p class="card-text font-small-3 mb-0">
                      Humidité
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 mb-1">
                <div class="media">
                  <div class="avatar bg-light-warning mr-2">
                    <div class="avatar-content">
                      <i data-feather="sun" class="avatar-icon"></i>
                    </div>
                  </div>
                  <div class="media-body my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      1000
                    </h4>
                    <p class="card-text font-small-3 mb-0">
                      Luminosité
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-xl-6 mb-1">
                <div class="media">
                  <div class="avatar mr-2">
                    <div class="avatar-content">
                      <i data-feather="cloud" class="avatar-icon"></i>
                    </div>
                  </div>
                  <div class="media-body my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      600 ppm
                    </h4>
                    <p class="card-text font-small-3 mb-0">
                      CO2
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body statistics-body no-data-found"
            *ngIf="this.ambianceHallTracker?.get('temperature') == undefined">
            <div class="no-data-text">
              Aucune donnée disponible
            </div>
          </div>
        </div>

        <div class="card earnings-card card-user-timeline">
          <div class="card-header">
            <div class="d-flex align-items-center">
              <i data-feather="user-plus" class="user-timeline-title-icon"></i>
              <h4 class="card-title">FMI</h4>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div id="fmi-donut-chart" #fmiChartRef>
                  <apx-chart [chart]="{
                          type: 'donut',
                          height: 120,
                          toolbar: {
                            show: false
                          }
                        }" [dataLabels]="fmiChartOptions.dataLabels" [series]="fmiChartOptions.series"
                    [legend]="fmiChartOptions.legend" [labels]="fmiChartOptions.labels"
                    [stroke]="fmiChartOptions.stroke" [colors]="fmiChartOptions.colors" [grid]="fmiChartOptions.grid"
                    [plotOptions]="fmiChartOptions.plotOptions"></apx-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--/ FMI Chart -->

    </div>
  </div>
</div>