import {Component, Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { OrganizationUpdateDialogComponent } from 'app/main/settings/organization-update-dialog/organization-update-dialog.component';
import { IOrganization } from 'app/models/organization.model';

@Injectable({providedIn: 'root'})
export class OrganizationUpdateDialogService {
  private isOpen = false;

  constructor(private modalService: NgbModal) {
  }

  open(organization: IOrganization): NgbModalRef {
    this.isOpen = true;
    const modalRef = this.modalService.open(OrganizationUpdateDialogComponent as Component, {
      size: 'lg',
      backdrop: 'static',
    });
    modalRef.componentInstance.organization = organization;
    modalRef.result.then(
      () => {
        this.isOpen = false;
      },
      () => {
        this.isOpen = false;
      }
    );
    return modalRef;
  }
}
