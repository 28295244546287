import { Component, Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UserManagementUpdateDialogComponent } from 'app/main/user-management/user-management-update-dialog/user-management-update-dialog.component';
import { IUser } from 'app/models/user-management.model';

@Injectable({ providedIn: 'root' })
export class UserManagementUpdateDialogService {
  private isOpen = false;

  constructor(private modalService: NgbModal) {
  }

  open(user: IUser): NgbModalRef {
    this.isOpen = true;
    const modalRef = this.modalService.open(UserManagementUpdateDialogComponent as Component, {
      size: 'lg',
      backdrop: 'static',
    });
    modalRef.componentInstance.user = user;
    modalRef.result.then(
      () => {
        this.isOpen = false;
      },
      () => {
        this.isOpen = false;
      }
    );
    return modalRef;
  }
}
