import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/config/pagination.constants';
import { IDevice } from 'app/models/device.model';
import { ILocation, Location } from 'app/models/location.model';
import { IMeasure } from 'app/models/measure.model';
import { IOrganization, Organization } from 'app/models/organization.model';
import { DeviceService } from 'app/services/device.service';
import { LocationDeleteDialogService } from 'app/services/location-delete-dialog.service';
import { LocationUpdateDialogService } from 'app/services/location-update-dialog.service';
import { LocationService } from 'app/services/location.service';
import { MeasureService } from 'app/services/measure.service';
import { OrganizationUpdateDialogService } from 'app/services/organization-update-dialog.service';
import { OrganizationService } from 'app/services/organization.service';
import { EMPTY, of } from "rxjs";
import { mergeMap } from 'rxjs/operators'
import { combineLatest } from 'rxjs';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SettingsComponent implements OnInit {
  organization: IOrganization | null = null;
  locations?: ILocation[];
  isLoading = false;

  totalItems = 0;
  itemsPerPage = ITEMS_PER_PAGE;
  page?: number;
  predicate!: string;
  ascending!: boolean;
  ngbPaginationPage = 1;

  private modalRef?: Promise<void>;

  constructor(
    protected organizationService: OrganizationService,
    protected organizationUpdateDialogService: OrganizationUpdateDialogService,
    protected locationService: LocationService,
    protected deviceService: DeviceService,
    protected measureService: MeasureService,
    protected locationUpdateDialogService: LocationUpdateDialogService,
    protected locationDeleteDialogService: LocationDeleteDialogService,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
  ) { }

  ngOnInit() {

    this.organizationService
      .find(1)
      .pipe(
        mergeMap((rganization: HttpResponse<Organization>) => {
          if (rganization.body) {
            return of(rganization.body);
          } else {
            return EMPTY;
          }
        })
      )
      .subscribe((organization: any) => {
        this.organization = organization;
      });

    this.handleNavigation();
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.isLoading = true;
    const pageToLoad: number = page ?? this.page ?? 1;

    this.locationService
      .query(
        {
          page: pageToLoad - 1,
          size: this.itemsPerPage,
          sort: this.sort(),
        }
      )
      .subscribe({
        next: async (res: HttpResponse<ILocation[]>) => {
          this.isLoading = false;
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        error: () => {
          this.isLoading = false;
          this.onError();
        },
      });
  }

  calculateNumberOfSensors(location?: Location): number {
    if (location != undefined) {

    }
    return 0;
  }

  updateInformations(event: any): void {
    this.modalRef = this.organizationUpdateDialogService.open(event).result.then(
      () => {
        this.organizationService
          .find(1)
          .pipe(
            mergeMap((organization: HttpResponse<Organization>) => {
              if (organization.body) {
                return of(organization.body);
              } else {
                return EMPTY;
              }
            })
          )
          .subscribe((organization: any) => {
            this.organization = organization;
          });
      },
      () => {
        this.organizationService
          .find(1)
          .pipe(
            mergeMap((organization: HttpResponse<Organization>) => {
              if (organization.body) {
                return of(organization.body);
              } else {
                return EMPTY;
              }
            })
          )
          .subscribe((organization: any) => {
            this.organization = organization;
          });
      }
    );
  }

  updateLocation(event: any): void {
    this.modalRef = this.locationUpdateDialogService.open(event).result.then(
      () => {
        this.loadPage();
      },
      () => {
        this.loadPage();
      }
    );
  }

  deleteLocation(event: any): void {
    this.modalRef = this.locationDeleteDialogService.open(event).result.then(
      () => {
        this.locationService
          .query(
            {
              size: 1000,
            }
          )
          .subscribe({
            next: (res: HttpResponse<ILocation[]>) => {
              this.locations = res.body ?? [];

              this.loadPage();
            },
            error: () => { },
          });
      },
      () => {
        this.locationService
          .query(
            {
              size: 1000,
            }
          )
          .subscribe({
            next: (res: HttpResponse<ILocation[]>) => {
              this.locations = res.body ?? [];

              this.loadPage();
            },
            error: () => { },
          });
      }
    );
  }

  calculateNumberOfMeasures(location: Location): number {
    let total = 0;
    if (location != undefined && location.devices != undefined) {
      location.devices.forEach(device => {
        if (device.measures != undefined) {
          total += device.measures.length;
        }
      });
    }
    return total;
  }

  trackId(_index: number, item: ILocation): number {
    return item.id!;
  }

  protected sort(): string[] {
    const result = [this.predicate + ',' + (this.ascending ? ASC : DESC)];
    if (this.predicate !== 'id') {
      result.push('id');
    }
    return result;
  }

  protected handleNavigation(): void {
    combineLatest([this.activatedRoute.data, this.activatedRoute.queryParamMap]).subscribe(([data, params]) => {
      const page = params.get('page');
      const pageNumber = +(page ?? 1);
      const sort = (params.get(SORT) ?? data['defaultSort']).split(',');
      const predicate = sort[0];
      const ascending = sort[1] === ASC;
      if (pageNumber !== this.page || predicate !== this.predicate || ascending !== this.ascending) {
        this.predicate = predicate;
        this.ascending = ascending;
        this.loadPage(pageNumber, true);
      }
    });
  }

  protected async onSuccess(data: ILocation[] | null, headers: HttpHeaders, page: number, navigate: boolean): Promise<void> {
    this.totalItems = Number(headers.get('X-Total-Count'));
    this.page = page;
    if (navigate) {
      this.router.navigate(['/settings'], {
        queryParams: {
          page: this.page,
          size: this.itemsPerPage,
          sort: this.predicate + ',' + (this.ascending ? ASC : DESC),
        },
      });
    }
    this.locations = data ?? [];

    const promises = this.locations.map(async (location) => this.deviceService
      .findAllByByLocationId(
        {
          size: 500,
        },
        location.id
      )
      .subscribe(
        {
          next: async (res: HttpResponse<IDevice[]>) => {
            this.isLoading = false;

            location.devices = res.body ?? [];

            const promises = location.devices.map(async (device) => this.measureService
              .findAllByByDeviceId(
                {
                  size: 500,
                },
                device.id
              )
              .subscribe(
                {
                  next: (res: HttpResponse<IMeasure[]>) => {
                    device.measures = res.body ?? [];
                  },
                  error: () => { },
                }
              ));

            await Promise.all(promises);
          },
          error: () => { },
        }
      ));

    await Promise.all(promises);
    this.ngbPaginationPage = this.page;
  }

  protected onError(): void {
    this.ngbPaginationPage = this.page ?? 1;
  }
}
