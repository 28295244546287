import { Component, Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MeasureUpdateDialogComponent } from 'app/main/measure/measure-update-dialog/measure-update-dialog.component';
import { IMeasure } from 'app/models/measure.model';

@Injectable({ providedIn: 'root' })
export class MeasureUpdateDialogService {
  private isOpen = false;

  constructor(private modalService: NgbModal) {
  }

  open(measure: IMeasure): NgbModalRef {
    this.isOpen = true;
    const modalRef = this.modalService.open(MeasureUpdateDialogComponent as Component, {
      size: 'lg',
      backdrop: 'static',
    });
    modalRef.componentInstance.measure = measure;
    modalRef.result.then(
      () => {
        this.isOpen = false;
      },
      () => {
        this.isOpen = false;
      }
    );
    return modalRef;
  }
}
