<form name="editForm" role="form" novalidate (ngSubmit)="save()" [formGroup]="editForm">
  <div class="modal-header">
    <h4 class="modal-title" id="measure-heading" data-cy="MeasureCreateUpdateHeading">
      Éditer une mesure
    </h4>
  </div>

  <div class="modal-body">
    <div class="form-group row">
      <div class="col-lg-6">
        <label for="field_description">Nom</label>
        <input type="text" class="form-control" name="description" id="field_description" data-cy="description" formControlName="description"/>
      </div>
      <div class="col-lg-6">
        <label for="device">Capteur</label>
        <ng-select name="device" notFoundText="Aucun élément trouvé" [items]="devicesSharedCollection" bindLabel="devEUI" formControlName="device" [readonly]="true">
        </ng-select>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-lg-6">
        <label for="field_lastValue">Dernière mesure</label>
        <input type="number" class="form-control" name="lastValue" id="field_lastValue" data-cy="lastValue" formControlName="lastValue" readonly/>
      </div>
      <div class="col-lg-6">
        <label for="field_minValue">Mesure minimale</label>
        <input type="number" class="form-control" name="minValue" id="field_minValue" data-cy="minValue" formControlName="minValue" />
      </div>
    </div>

    <div class="form-group row">
      <div class="col-lg-6">
        <label for="field_unit">Unité</label>
        <input type="text" class="form-control" name="unit" id="field_unit" data-cy="unit" formControlName="unit" />
      </div>
      <div class="col-lg-6">
        <label for="field_maxValue">Mesure maximale</label>
        <input type="number" class="form-control" name="maxValue" id="field_maxValue" data-cy="maxValue" formControlName="maxValue" />
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancel()">
      <span>Annuler</span>
    </button>

    <button type="submit" id="save-entity" data-cy="entityCreateSaveButton" [disabled]="editForm.invalid || isSaving"
      class="btn btn-primary">
      <span>Sauvegarder</span>
    </button>
  </div>
</form>