import { Component, OnInit } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { IOrganization, Organization } from 'app/models/organization.model';
import { OrganizationService } from 'app/services/organization.service';

@Component({
  selector: 'organization-update-dialog',
  templateUrl: './organization-update-dialog.component.html',
})
export class OrganizationUpdateDialogComponent implements OnInit {
  organization: IOrganization = new Organization();
  isSaving = false;

  formattedaddress = " ";
  options = {}

  constructor(
    protected organizationService: OrganizationService,
    protected activatedRoute: ActivatedRoute,
    protected fb: FormBuilder,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.isSaving = false;
  }

  public AddressChange(address: any) {
    this.formattedaddress = address.formatted_address
  }

  cancel(): void {
    this.activeModal.dismiss('cancel');
  }

  save(): void {
    this.isSaving = true;
    if (this.organization.id !== undefined) {
      this.subscribeToSaveResponse(this.organizationService.update(this.organization));
    } else {
      this.subscribeToSaveResponse(this.organizationService.create(this.organization));
    }
  }

  protected subscribeToSaveResponse(result: Observable<HttpResponse<IOrganization>>): void {
    result.pipe(finalize(() => this.onSaveFinalize())).subscribe(
      () => this.onSaveSuccess(),
      () => this.onSaveError()
    );
  }

  protected onSaveSuccess(): void {
    this.isSaving = false;
    this.activeModal.dismiss(true);
  }

  protected onSaveError(): void {
    // Api for inheritance.
  }

  protected onSaveFinalize(): void {
    this.isSaving = false;
  }
}
