export interface IOrganization {
  id?: number;
  name?: string | null;
  address?: string | null;
  country?: string | null;
  city?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  apiURL?: string | null;
  apiKEY?: string | null;
}

export class Organization implements IOrganization {
  constructor(
    public id?: number,
    public name?: string | null,
    public address?: string | null,
    public country?: string | null,
    public city?: string | null,
    public latitude?: number | null,
    public longitude?: number | null,
    public apiURL?: string | null,
    public apiKEY?: string | null
  ) {}
}

export function getOrganizationIdentifier(organization: IOrganization): number | undefined {
  return organization.id;
}
