import { Component } from '@angular/core';

import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ILocation } from 'app/models/location.model';
import { IUser, User } from 'app/models/user-management.model';
import { LocationService } from 'app/services/location.service';
import { UserManagementService } from 'app/services/user-management.service';
@Component({
  selector: 'user-management-delete-dialog',
  templateUrl: './user-management-delete-dialog.component.html',
})
export class UserManagementDeleteDialogComponent {
  user?: User;

  constructor(private userManagementService: UserManagementService, private activeModal: NgbActiveModal) { }

  cancel(): void {
    this.activeModal.dismiss();
  }

  confirmDelete(login: string): void {
    this.userManagementService.delete(login).subscribe(() => {
      this.activeModal.close('deleted');
    });
  }
}
