import {Component, Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { LocationUpdateDialogComponent } from 'app/main/settings/location-update-dialog/location-update-dialog.component';
import { ILocation } from 'app/models/location.model';

@Injectable({providedIn: 'root'})
export class LocationUpdateDialogService {
  private isOpen = false;

  constructor(private modalService: NgbModal) {
  }

  open(location: ILocation): NgbModalRef {
    this.isOpen = true;
    const modalRef = this.modalService.open(LocationUpdateDialogComponent as Component, {
      size: 'lg',
      backdrop: 'static',
    });
    modalRef.componentInstance.location = location;
    modalRef.result.then(
      () => {
        this.isOpen = false;
      },
      () => {
        this.isOpen = false;
      }
    );
    return modalRef;
  }
}
