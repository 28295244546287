<form name="editForm" role="form" novalidate (ngSubmit)="save()" #editForm="ngForm">
  <div class="modal-header">
    <h4 class="modal-title" id="organization-heading" data-cy="OrganizationCreateUpdateHeading">
      Modifier les informations
    </h4>
  </div>

  <div class="modal-body">
    <div class="form-group row">
      <div class="col-lg-6">
        <label for="field_name">Nom de l'établissement</label>
        <input type="text" class="form-control" name="name" id="field_name" data-cy="name"
          [(ngModel)]="organization.name" required="true" />
      </div>
      <div class="col-lg-6">
        <label for="field_address">Adresse</label>
        <input type="text" class="form-control" name="address" id="field_address" data-cy="address"
          [(ngModel)]="organization.address" required="true" />
      </div>
    </div>

    <div class="form-group row">
      <div class="col-lg-6">
        <label for="field_apiUrl">URL de l'API</label>
        <input type="text" class="form-control" name="apiURL" id="field_apiURL" data-cy="apiURL"
          [(ngModel)]="organization.apiURL" required="true" />
      </div>
      <div class="col-lg-6">
        <label for="field_apiUrl">Clé d'API</label>
        <input type="text" class="form-control" name="apiKey" id="field_apiKey" data-cy="apiKey"
          [(ngModel)]="organization.apiKEY" required="true" />
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancel()">
      <span>Annuler</span>
    </button>

    <button type="submit" id="save-entity" data-cy="entityCreateSaveButton" [disabled]="editForm.invalid || isSaving"
      class="btn btn-primary">
      <span>Sauvegarder</span>
    </button>
  </div>
</form>