import { Component } from '@angular/core';

import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ILocation } from 'app/models/location.model';
import { LocationService } from 'app/services/location.service';
@Component({
  selector: 'location-delete-dialog',
  templateUrl: './location-delete-dialog.component.html',
})
export class LocationDeleteDialogComponent {
  location?: ILocation;

  constructor(protected locationService: LocationService, public activeModal: NgbActiveModal) { }

  cancel(): void {
    this.activeModal.dismiss();
  }

  confirmDelete(id: number): void {
    this.locationService.delete(id).subscribe(() => {
      this.activeModal.close('deleted');
    });
  }
}
