import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'app/models/user-management.model';
import { UserManagementService } from 'app/services/user-management.service';

@Component({
  selector: 'user-management-update-dialog',
  templateUrl: './user-management-update-dialog.component.html',
})
export class UserManagementUpdateDialogComponent implements OnInit {
  user!: User;
  error = '';
  authorities: string[] = [];
  isSaving = false;

  editForm = this.fb.group({
    id: [],
    login: [
      '',
      [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50),
        Validators.pattern('^[a-zA-Z0-9!$&*+=?^_`{|}~.-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$|^[_.@A-Za-z0-9-]+$'),
      ],
    ],
    firstName: ['', [Validators.maxLength(50)]],
    lastName: ['', [Validators.maxLength(50)]],
    email: ['', [Validators.minLength(5), Validators.maxLength(254), Validators.email]],
    activated: [],
    langKey: [],
    authorities: [Validators.required],
  });

  constructor(
    private userService: UserManagementService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    if (this.user.id === undefined) {
      this.user.activated = true;
    }
    this.updateForm(this.user);
    this.userService.authorities().subscribe(authorities => (this.authorities = authorities));
  }

  cancel(): void {
    this.activeModal.dismiss('cancel');
  }

  save(): void {
    this.isSaving = true;
    this.updateUser(this.user);
    if (this.user.id !== undefined) {
      this.userService.update(this.user).subscribe({
        next: () => this.onSaveSuccess(),
        error: (error) => {
          if (error.status == 500) {
            this.error = 'Erreur interne du serveur.';
          } else if (error.status == 400) {
            if (error.error.errorKey == 'userexists') {
              this.error = 'Nom d\'utilisateur déjà utilisé !';
            } else if (error.error.errorKey == 'emailexists') {
              this.error = 'Email déjà utilisé !';
            }
          }
          this.onSaveError();
        },
      });
    } else {
      this.userService.create(this.user).subscribe({
        next: () => this.onSaveSuccess(),
        error: (error) => {
          if (error.status == 500) {
            this.error = 'Erreur interne du serveur.';
          } else if (error.status == 400) {
            this.error = 'Erreur interne du serveur.';
          }
          this.onSaveError();
        },
      });
    }
  }

  private updateForm(user: User): void {
    this.editForm.patchValue({
      id: user.id,
      login: user.login,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      activated: user.activated,
      langKey: user.langKey,
      authorities: user.authorities,
    });
  }

  private updateUser(user: User): void {
    user.login = this.editForm.get(['login'])!.value;
    user.firstName = this.editForm.get(['firstName'])!.value;
    user.lastName = this.editForm.get(['lastName'])!.value;
    user.email = this.editForm.get(['email'])!.value;
    user.activated = this.editForm.get(['activated'])!.value;
    user.langKey = this.editForm.get(['langKey'])!.value;
    user.authorities = this.editForm.get(['authorities'])!.value;
  }

  private onSaveSuccess(): void {
    this.isSaving = false;
    this.activeModal.dismiss(true);
  }

  private onSaveError(): void {
    this.isSaving = false;
  }
}
