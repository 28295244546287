import { Component, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core'
import { CoreConfigService } from '@core/services/config.service';
import { ApexAxisChartSeries, ApexChart, ApexXAxis, ApexDataLabels, ApexGrid, ApexStroke, ApexLegend, ApexTitleSubtitle, ApexTooltip, ApexPlotOptions, ApexYAxis, ApexFill, ApexMarkers, ApexTheme } from 'ng-apexcharts';

// interface ChartOptions
export interface ChartOptions {
  series?: ApexAxisChartSeries;
  chart?: ApexChart;
  xaxis?: ApexXAxis;
  dataLabels?: ApexDataLabels;
  grid?: ApexGrid;
  stroke?: ApexStroke;
  legend?: ApexLegend;
  title?: ApexTitleSubtitle;
  colors?: string[];
  tooltip?: ApexTooltip;
  plotOptions?: ApexPlotOptions;
  yaxis?: ApexYAxis;
  fill?: ApexFill;
  labels?: string[];
  markers: ApexMarkers;
  theme: ApexTheme;
}

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StatisticsComponent implements OnInit {
  @ViewChild('apexLineAreaChartRef') apexLineAreaChartRef: any;

  public isMenuToggled = false;

  public apexLineAreaChart: Partial<ChartOptions>;

  // Color Variables
  chartColors = {
    column: {
      series1: '#826af9',
      series2: '#d2b0ff',
      bg: '#f8d3ff'
    },
    success: {
      shade_100: '#7eefc7',
      shade_200: '#06774f'
    },
    donut: {
      series1: '#ffe700',
      series2: '#00d4bd',
      series3: '#826bf8',
      series4: '#2b9bf4',
      series5: '#FFA1A1'
    },
    area: {
      series3: '#a4f8cd',
      series2: '#60f2ca',
      series1: '#2bdac7'
    }
  };

  constructor(private _coreConfigService: CoreConfigService) {
    // Apex Line chart
    this.apexLineAreaChart = {
      series: [
        {
          name: 'Visits',
          data: [100, 120, 90, 170, 130, 160, 140, 240, 220, 180, 270, 280, 375]
        },
        {
          name: 'Clicks',
          data: [60, 80, 70, 110, 80, 100, 90, 180, 160, 140, 200, 220, 275]
        },
      ],
      chart: {
        height: 400,
        type: 'area',
        stacked: true,
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      colors: [this.chartColors.area.series1, this.chartColors.area.series2, this.chartColors.area.series3],
      dataLabels: {
        enabled: false
      },
      fill: {
        opacity: 1,
        type: 'solid'
      },
      tooltip: {
        shared: false
      },
      stroke: {
        show: false,
        curve: 'straight'
      },
      grid: {
        xaxis: {
          lines: {
            show: true
          }
        }
      },
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'left'
      },
      xaxis: {
        categories: [
          '7/12',
          '8/12',
          '9/12',
          '10/12',
          '11/12',
          '12/12',
          '13/12',
          '14/12',
          '15/12',
          '16/12',
          '17/12',
          '18/12',
          '19/12',
          '20/12'
        ]
      }
    };
  }

  ngOnInit() { }


  @HostListener('window:resize', ['$event'])
  onResize() {
    this.apexLineAreaChart.chart.width = this.apexLineAreaChartRef?.nativeElement.offsetWidth;
  }
}
