<form name="editForm" role="form" novalidate (ngSubmit)="save()" [formGroup]="editForm">
  <div class="modal-header">
    <h4 class="modal-title" id="location-heading" data-cy="LocationCreateUpdateHeading">
      Créer ou éditer un lieu
    </h4>
  </div>

  <div class="modal-body">
    <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
      <div class="alert-body">
        <p>{{ error }}</p>
      </div>
    </ngb-alert>

    <div class="form-group row">
      <div class="col-lg-12">
        <label for="field_name">Nom de lieu</label>
        <input type="text" class="form-control" name="name" id="field_name" data-cy="name" formControlName="name"
          required="true" />
      </div>
    </div>
    <div class="form-group row" *ngIf="location.id != undefined">
      <div class="col-lg-12">
        <label for="field_address">Capteurs</label>
        <ng-select id="field_devices" name="devices" notFoundText="Aucun élément trouvé"
          [items]="devicesSharedCollection" [multiple]="true" bindLabel="devEUI" formControlName="devices">

          <ng-template ng-option-tmp let-item="item" let-index="index">
            <div>Nom : {{ item.name }}</div>
            <div>Code : {{ item.code }}</div>
            <div>devEUI : {{ item.devEUI }}</div>
          </ng-template>

        </ng-select>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancel()">
      <span>Annuler</span>
    </button>

    <button type="submit" id="save-entity" data-cy="entityCreateSaveButton" [disabled]="editForm.invalid || isSaving"
      class="btn btn-primary">
      <span>Sauvegarder</span>
    </button>
  </div>
</form>