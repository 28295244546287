<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- / Informations section start -->
    <core-card [actions]="[]" [reloadTime]="'1000'">
      <h4 class="card-title ">
        <div>Informations</div>
        <div (click)="updateInformations(organization)">
          <i data-feather="edit-2" class="mr-50 cursor-style"></i>
        </div>
      </h4>
      <div class="card-body ">
        <form class="form">
          <div class="row">
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="field_name">Nom de l'établissement</label>
                <span *ngIf="organization?.name === null" type="text" id="field_name" class="form-control span-input" name="field_name">-</span>
                <span *ngIf="organization?.name !== null" type="text" id="field_name" class="form-control span-input" name="field_name">{{ organization.name }}</span>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="field_address">Adresse</label>
                <span *ngIf="organization?.address === null" type="text" id="field_address" class="form-control span-input" name="field_address">-</span>
                <span *ngIf="organization?.address !== null" type="text" id="field_address" class="form-control span-input" name="field_address">{{ organization.address }}</span>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="field_apiUrl">URL de l'API</label>
                <span *ngIf="organization?.apiURL === null" type="text" id="field_apiUrl" class="form-control span-input" name="field_apiUrl">-</span>
                <span *ngIf="organization?.apiURL !== null" type="text" id="field_apiUrl" class="form-control span-input" name="field_apiUrl">{{ organization.apiURL }}</span>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="field_apiUrl">Clé d'API</label>
                <span *ngIf="organization?.apiKEY === null" type="text" id="field_apiUrl" class="form-control span-input" name="field_apiUrl">-</span>
                <span *ngIf="organization?.apiKEY !== null" type="text" id="field_apiKey" class="form-control span-input apikey-span" name="field_apiKey">{{ organization.apiKEY }}</span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </core-card>
    <!-- Informations section end -->

    <!-- Places section start -->
    <div class="row" id="table-hover-row  mb-1">
      <div class="col-12">
        <core-card [actions]="[]" [reloadTime]="'1000'">
          <h4 class="card-title">
            <div>Liste des lieux</div>
            <div (click)="updateLocation($event)">
              <i data-feather="plus" class="mr-50 cursor-style"></i>
            </div>
          </h4>
          <div *ngIf="locations && locations.length === 0" class="no-data">
            <div class="no-data-text">
              Aucune donnée trouvée
            </div>
          </div>
          <div class="table-responsive" *ngIf="locations && locations.length > 0">
            <table class="table table-hover">
              <thead>
                <tr jhiSort [(predicate)]="predicate" [(ascending)]="ascending" (sortChange)="loadPage()">
                  <th scope="col" jhiSortBy="id">
                    ID <fa-icon icon="sort"></fa-icon>
                  </th>
                  <th scope="col" jhiSortBy="name">
                    Lieu <fa-icon icon="sort"></fa-icon>
                  </th>
                  <th>Capteurs</th>
                  <th>Mesures</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let location of locations; trackBy: trackId">
                  <td>{{ location.id }}</td>
                  <td>{{ location.name }}</td>
                  <td>{{ location.devices?.length }}</td>
                  <td>{{ calculateNumberOfMeasures(location) }}</td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem [routerLink]="['/measures/location/', location.id]" *ngIf="calculateNumberOfMeasures(location) > 0">
                          <i data-feather="pie-chart" class="mr-50"></i>
                          <span>Mesures</span>
                        </a>
                        <a ngbDropdownItem (click)="updateLocation(location)">
                          <i data-feather="edit-2" class="mr-50"></i>
                          <span>Modifier</span>
                        </a>
                        <a ngbDropdownItem (click)="deleteLocation(location)" *ngIf="location.id > 2">
                          <i data-feather="trash" class="mr-50"></i>
                          <span>Supprimer</span>
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="locations && locations.length > 0">
            <div class="d-flex justify-content-center">
              <jhi-item-count [params]="{ page: page, totalItems: totalItems, itemsPerPage: itemsPerPage }"></jhi-item-count>
            </div>
        
            <div class="d-flex justify-content-center">
              <ngb-pagination
                [collectionSize]="totalItems"
                [(page)]="ngbPaginationPage"
                [pageSize]="itemsPerPage"
                [maxSize]="5"
                [rotate]="true"
                [boundaryLinks]="true"
                (pageChange)="loadPage($event)"
              ></ngb-pagination>
            </div>
          </div>
        </core-card>
      </div>
    </div>
    <!--/ Places section end -->
  </div>
</div>