import dayjs from 'dayjs/esm';
import { IMeasure } from 'app/models/measure.model';

export interface IAlert {
  id?: number;
  alertDate?: dayjs.Dayjs | null;
  reason?: string | null;
  hidden?: boolean | null;
  measure?: IMeasure | null;
}

export class Alert implements IAlert {
  constructor(public id?: number, public alertDate?: dayjs.Dayjs | null, public reason?: string | null, public hidden?: boolean, public measure?: IMeasure | null) {}
}

export function getAlertIdentifier(alert: IAlert): number | undefined {
  return alert.id;
}
