import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CoreCommonModule } from '@core/common.module';

import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { SettingsComponent } from './settings/settings.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { CurrentForecastComponent } from './current-forecast/current-forecast.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreCardModule } from '@core/components/core-card/core-card.module';
import { OrganizationUpdateDialogComponent } from './settings/organization-update-dialog/organization-update-dialog.component';
import { LocationUpdateDialogComponent } from './settings/location-update-dialog/location-update-dialog.component';
import { LocationDeleteDialogComponent } from './settings/location-delete-dialog/location-delete-dialog.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { UserRouteAccessService } from 'app/core/auth/user-route-access.service';
import { SharedModule } from 'app/shared/shared.module';
import { UserManagementUpdateDialogComponent } from './user-management/user-management-update-dialog/user-management-update-dialog.component';
import { UserManagementDeleteDialogComponent } from './user-management/user-management-delete-dialog/user-management-delete-dialog.component';
import { MeasureComponent } from './measure/measure.component';
import { LocationRoutingResolveService } from 'app/services/location-routing-resolve.service';
import { MeasureUpdateDialogComponent } from './measure/measure-update-dialog/measure-update-dialog.component';

const routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: { animation: 'dashboard' },
    canActivate: [UserRouteAccessService]
  },
  {
    path: 'statistics',
    component: StatisticsComponent,
    data: { animation: 'statistics' },
    canActivate: [UserRouteAccessService]
  },
  {
    path: 'settings',
    component: SettingsComponent,
    data: {
      animation: 'settings',
      defaultSort: 'id,asc',
    },
    canActivate: [UserRouteAccessService]
  },
  {
    path: 'user-management',
    component: UserManagementComponent,
    data: {
      animation: 'user-management',
      defaultSort: 'id,asc',
    },
    canActivate: [UserRouteAccessService]
  },
  {
    path: 'measures/location/:id',
    component: MeasureComponent,
    data: {
      animation: 'measures',
      defaultSort: 'id,asc',
    },
    resolve: {
      location: LocationRoutingResolveService,
    },
    canActivate: [UserRouteAccessService]
  },
];

@NgModule({
  declarations: [
    DashboardComponent,
    StatisticsComponent,
    SettingsComponent,
    MeasureComponent,
    UserManagementComponent,
    CurrentForecastComponent,
    OrganizationUpdateDialogComponent,
    LocationUpdateDialogComponent,
    LocationDeleteDialogComponent,
    UserManagementUpdateDialogComponent,
    UserManagementDeleteDialogComponent,
    MeasureUpdateDialogComponent
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    NgSelectModule, CoreCardModule,
    ContentHeaderModule,
    CoreCommonModule,
    NgApexchartsModule,
    NgbModule
  ],
  exports: [
    DashboardComponent,
    StatisticsComponent,
    SettingsComponent,
    MeasureComponent,
    UserManagementComponent,
    CurrentForecastComponent,
    OrganizationUpdateDialogComponent,
    LocationUpdateDialogComponent,
    LocationDeleteDialogComponent,
    UserManagementUpdateDialogComponent,
    UserManagementDeleteDialogComponent,
    MeasureUpdateDialogComponent
  ]
})
export class MainModule { }
