import { IDevice } from 'app/models/device.model';

export interface ILocation {
  id?: number;
  name?: string | null;
  devices?: IDevice[] | null;
}

export class Location implements ILocation {
  constructor(public id?: number, public name?: string | null, public devices?: IDevice[] | null) {}
}

export function getLocationIdentifier(location: ILocation): number | undefined {
  return location.id;
}
