import { Component, OnInit } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ILocation, Location } from 'app/models/location.model';
import { LocationService } from 'app/services/location.service';
import { IDevice } from 'app/models/device.model';
import { DeviceService } from 'app/services/device.service';
@Component({
  selector: 'location-update-dialog',
  templateUrl: './location-update-dialog.component.html',
})
export class LocationUpdateDialogComponent implements OnInit {
  location: ILocation = new Location();
  devicesSharedCollection: IDevice[] = [];
  isSaving = false;
  error = '';

  editForm = this.fb.group({
    id: [],
    name: ['', [Validators.required]],
    devices: [],
  });

  constructor(
    protected deviceService: DeviceService,
    protected locationService: LocationService,
    protected activatedRoute: ActivatedRoute,
    protected fb: FormBuilder,
    public activeModal: NgbActiveModal
  ) { }

  async ngOnInit(): Promise<void> {
    this.isSaving = false;

    this.updateForm(this.location);

    this.deviceService
      .query({
        size: 500
      })
      .pipe(map((res: HttpResponse<IDevice[]>) => res.body ?? []))
      .pipe(map((devices: IDevice[]) => this.deviceService.addDeviceToCollectionIfMissing(devices, this.editForm.get('devices')!.value)))
      .subscribe((devices: IDevice[]) => (this.devicesSharedCollection = devices));
  }

  cancel(): void {
    this.activeModal.dismiss('cancel');
  }

  save(): void {
    this.isSaving = true;
    const location = this.createFromForm();
    if (location.id !== undefined) {
      this.subscribeToSaveLocationResponse(this.locationService.update(location), location);
    } else {
      this.subscribeToSaveLocationResponse(this.locationService.create(location), location);
    }
  }

  protected subscribeToSaveLocationResponse(result: Observable<HttpResponse<ILocation>>, location: ILocation): void {
    result.pipe(finalize(() => this.onSaveFinalize())).subscribe(
      () => this.onSaveLocationSuccess(location),
      (error) => this.onSaveError(error)
    );
  }

  protected subscribeToSaveDeviceResponse(result: Observable<HttpResponse<IDevice>>): void {
    result.pipe(finalize(() => this.onSaveFinalize())).subscribe(
      () => this.onSaveSuccess(),
      () => this.onSaveError()
    );
  }

  protected onSaveLocationSuccess(location: ILocation): void {
    if (location != undefined && location.id != undefined) {
      let loc = new Location();
      this.deviceService
        .findAllByByLocationId(
          {
            size: 500,
          },
          location.id
        )
        .subscribe(
          {
            next: async (res: HttpResponse<IDevice[]>) => {
              loc.devices = res.body ?? [];

              const promises = loc.devices.map(async (device) => {
                device.location = null;

                this.subscribeToSaveDeviceResponse(this.deviceService.update(device));
              });

              await Promise.all(promises).then(() => {
                setTimeout(async () => {
                  const promises = location.devices.map((device) => {
                    device.location.id = location.id;
                    device.location.name = location.name;

                    this.subscribeToSaveDeviceResponse(this.deviceService.update(device));
                  });

                  await Promise.all(promises);

                  this.isSaving = false;
                  this.activeModal.dismiss(true);
                }, 150);
              });
            },
            error: () => { },
          }
        );
    } else {
      this.activeModal.dismiss(true);
    }
  }

  protected onSaveSuccess(): void { }

  protected onSaveError(error?: any): void {
    if (error != undefined) {
      if (error.status == 500) {
        this.error = 'Erreur interne du serveur.';
      } else if (error.status == 400) {
        if (error.error.errorKey == 'locationexists') {
          this.error = 'Nom de lieu déjà utilisé !';
        }
      }
    }
  }

  protected onSaveFinalize(): void {
    this.isSaving = false;
  }

  protected updateForm(location: ILocation): void {
    this.editForm.patchValue({
      id: this.location.id,
      name: this.location.name,
      devices: this.location.devices,
    });
  }

  protected createFromForm(): ILocation {
    return {
      ...new Location(),
      id: this.editForm.get(['id'])!.value,
      name: this.editForm.get(['name'])!.value,
      devices: this.editForm.get(['devices'])!.value,
    };
  }
}
