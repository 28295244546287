import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
  {
    id: 'dashboard',
    title: 'Accueil',
    type: 'item',
    icon: 'home',
    url: 'dashboard'
  },
  {
    id: 'statistics',
    title: 'Statistiques',
    type: 'item',
    icon: 'bar-chart-2',
    url: 'statistics'
  },
  {
    id: 'settings',
    title: 'Configuration',
    type: 'item',
    icon: 'settings',
    url: 'settings'
  },
  {
    id: 'user-management',
    title: 'Utilisateurs',
    type: 'item',
    icon: 'users',
    url: 'user-management'
  }
]
